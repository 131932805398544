<template>
    <!-- 可见范围 -->
    <CompModal ref="comp_modal" title="设置可见范围" :onSuccess="onSubmitVisibleRange" @on-close="onChangeVisible" width="500px" height="auto">
        <div class="visible-range-form-box">
            <RadioGroup v-model="visibleRangeForm.type">
                <Radio label="1">机构全部人员</Radio>
                <Radio label="2">机构指定人员</Radio>
            </RadioGroup>
            <div class="select-box" v-show="visibleRangeForm.type == '2'">
                <ElemCheck column search multiple :value="visibleRangeForm.member" :list="orgStaffList" @on-change="onChangeDesignatedOfficer"></ElemCheck>
            </div>
        </div>
    </CompModal>
</template>

<script>
import proxy from "@/api/proxy"

import ElemCheck from "@/views/customform/components/ElemCheck.vue"
import Request from "../../jointly/utils/request"
import CompModal from "../components/CompModal"

export default {
    components: { ElemCheck, CompModal },

    data() {
        return {
            // 设置可见范围页面
            isDisplayRangeModal: false,
            // 可见范围表单数据
            visibleRangeForm: {},
            // 机构员工列表
            orgStaffList: null,
        }
    },

    watch: {
        "visibleRangeForm.type"(v) {
            if (v === "2" && !this.orgStaffList) {
                this.getStaffList()
            }
        },
    },

    methods: {
        display(appId) {
            this.appId = appId
            // 获取详情数据
            this.getDetailData()
            // 显示弹窗
            this.$refs.comp_modal.display()
        },

        getStaffList() {
            Request.get("/old/api/pc/staff/selectBaseManagerStaffList", {
                userType: "09",
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                staffId: parent.vue.loginInfo.userinfo.id,
            }).then(res => {
                this.orgStaffList = res?.map(v => ({
                    name: v.name,
                    value: v.custGlobalId,
                }))
            })
        },

        getDetailData() {
            this.$get("/gateway/api/fastDev/AppForm/findAppAuthUser", {
                appId: this.appId,
            }).then(res => {
                if (res.code == 200) {
                    let data = res.data

                    if (data.scopeType == 2 && data.custGlobalIdList.length > 0 && data.custGlobalIdList.length === data.userNameList.length) {
                        this.$set(this.visibleRangeForm, "member", data.custGlobalIdList)

                        var members = []

                        for (let i = 0, l = data.custGlobalIdList; i < l.length; i++) {
                            members.push({
                                name: data.userNameList?.[i],
                                value: l[i],
                            })
                        }

                        this.visibleRangeForm.members = members
                    }

                    this.$set(this.visibleRangeForm, "type", data.scopeType)
                }
            })
        },

        /**
         * 监听弹窗显示状态变化
         */
        onChangeVisible(evt) {
            // 关闭弹窗时，清空表单数据
            this.visibleRangeForm = {}
        },

        /**
         * 监听指点人员变化事件
         */
        onChangeDesignatedOfficer(evt) {
            this.visibleRangeForm.members = evt.value
        },

        /**
         * 设置可见范围
         */
        onSubmitVisibleRange() {
            const vrf = this.visibleRangeForm

            if (!vrf.type) return

            const formdata = new FormData()
            formdata.append("appId", this.appId)
            formdata.append("scopeType", vrf.type)

            if (vrf.type == "2") {
                if (!vrf.members || vrf.members.length <= 0) return this.$Message.warning("请至少选择一名人员")
                for (let i = 0, l = vrf.members; i < l.length; i++) {
                    formdata.append("custGlobalIdList", l[i].value)
                    formdata.append("userNameList", l[i].name)
                }
            } else {
                formdata.append("custGlobalIdList", "")
                formdata.append("userNameList", "")
            }

            const xhr = new XMLHttpRequest()

            xhr.open("POST", `${proxy["/gateway"].target}/api/fastDev/AppForm/appAuthUser`)
            xhr.setRequestHeader("Authorization", window.sessionStorage.getItem("accessToken"))

            xhr.onloadstart = () => {
                this.$Loading.start()
            }

            xhr.onloadend = () => {
                this.$Loading.finish()
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const res = JSON.parse(xhr.response)
                    if (res.code == 200) {
                        this.$Message.success("设置成功")
                        // 关闭窗口
                        this.$refs.comp_modal.close()
                    } else this.$Message.error(res.desc)
                }
            }

            xhr.send(formdata)
        },
    },
}
</script>

<style lang="less">
.visible-range-form-box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ivu-radio-wrapper {
        margin-right: 20px;
    }

    .select-box {
        width: 100%;
        margin-top: 20px;

        .group.column {
            flex-direction: row !important;
            flex-wrap: wrap;
        }

        .ivu-checkbox-wrapper {
            width: 50%;
        }
    }
}
</style>
